<template>
    <v-card>
        <v-card-title>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
        </v-card-title>

        <v-divider></v-divider>

        <v-dialog
            v-model="showDetailQuest"
            scrollable
            @click:outside="close()"
        >
            <questRepeatedView v-if="showDetailQuest" :pSelectedQuest="this.selectedQuest" :pLogicSimulatorService="logicSimulatorService"/>
        </v-dialog>


        <v-card  max-height="700" class="overflow-y-auto">
            <v-data-table
                :headers="headers"
                :items="questList"
                class="elevation-1"
                hide-default-footer
                :options="{itemsPerPage:questList.length}"
                :search="search"
                show-group-by
                show-select
                @click:row="didClickOnRow"
                :item-class= "rowClass"
            >
                <template v-slot:item.mainTitle="{item}">
                    {{item.mainTitle}}
                    <v-edit-dialog
                        :return-value.sync="item.mainTitle"
                    >
                        <template v-slot:input>
                    <pre>
                      {{item}}

                    </pre>
                        </template>
                    </v-edit-dialog>

                </template>
                <template v-slot:item.difficulty="{item}">

                    <v-edit-dialog
                        :return-value.sync="item.difficulty"
                    >
                        {{ item.difficulty }}
                    </v-edit-dialog>

                </template>
                <template v-slot:item.type="{item}">

                    <v-edit-dialog
                        :return-value.sync="item.type"
                    >
                        {{ item.type }}
                    </v-edit-dialog>

                </template>
                <template v-slot:item.formulaType="{item}">

                    <v-edit-dialog
                        :return-value.sync="item.formulaType"
                    >
                        {{ item.formulaType }}

                        <template v-slot:input>
                            {{item.formulaType}}
                            <v-select
                                v-model="item.formulaType"
                                :items="[
                                                'Arithmétique','Géométrique','KPI'
                                            ]"
                                label="Formule"
                            ></v-select>

                        </template>
                    </v-edit-dialog>

                </template>
                <template v-slot:item.questTitleCode="{item}">
                    {{ getFullLabel(item) }}
                </template>
                <template v-slot:item.ordered="{item}">
                    {{getRewardValue(item)}}
                </template>
                <template v-slot:item.target="{item}">
                    {{getFullLabelChallenge(item)}}
                </template>

            </v-data-table>


            <v-dialog
                v-model="showSimulation"
                scrollable
                @click:outside="close()"
            >
                <simulationView
                        v-if="showSimulation"
                        :pQuestList="this.pQuestList"
                        pTitle="Mini Quest Simulator"
                        :pLogicSimulatorService="this.logicSimulatorService"
                        @close="close()">
                </simulationView>
            </v-dialog>

            <v-dialog
                    v-model="showDailyQuestSimulation"
                    scrollable
                    @click:outside="close()"
            >
                <simulationView
                        v-if="showDailyQuestSimulation"
                        :pQuestList="this.pDailyQuestList"
                        :pTitle="this.getDailyQuestTitle()"
                        :pDailyGameLevelPerDay="this.$store.state.ratios.dailyGameLevelPerDay"
                        :pLogicSimulatorService="logicSimulatorService"
                        @close="close()">
                </simulationView>
            </v-dialog>

        </v-card>

        <v-divider></v-divider>

        <v-card>
            <v-row>
                <v-col cols="2">
                    Total quests: {{questList.length}}
                </v-col>
<!--                <v-col class="light-green&#45;&#45;text">-->
<!--                    Validated: {{validatedCount}} ({{validatePercent}}%)-->
<!--                </v-col>-->
            </v-row>
        </v-card>
        <v-card-actions>
            <v-btn color="blue darken-1" text @click="didClickSimulation()">Mini Quest Simulation</v-btn>
            <v-btn color="blue darken-1" text @click="didClickMiniQuestSimulation()">Daily Quest Simulation</v-btn>
        </v-card-actions>

<!--        <v-card-actions>-->
<!--            <v-btn color="blue darken-1" text @click="ExportCSV()">Export</v-btn>-->
<!--            <v-btn color="blue darken-1" text @click="dispatchClose()">Close</v-btn>-->
<!--            <v-btn color="green darken-1" text @click="dispatchSave()">Save</v-btn>-->
<!--            <v-btn color="yellow darken-1" text @click="filterDynamic()">-->
<!--                {{ this.showDynamic ? "With Dynamic" : "No Dynamic" }}-->
<!--            </v-btn>-->

<!--        </v-card-actions>-->

    </v-card>
</template>

<script>
import lodash from "lodash";
import GameplayQuest from "@/quest-editor/questImpl/SMPGamePlayQuest";
import QuestConstant from "@/constants/QuestConstants";
import QuestRepeatedView from "@/quest-editor/modules/quest-list/components/QuestRepeatedView";
import QuestSimulationView from "@/quest-editor/modules/simulation/QuestSimulationView";
import LogicSimulationService from "@/kpi-editor/services/LogicSimulationService";
import BalanceKpiUtils from "@/kpi-editor/Utils/BalanceKpiUtils";
import SupportKpiUtils from "@/kpi-editor/Utils/SupportKpiUtils";
import PetKpiUtils from "@/kpi-editor/Utils/PetKpiUtils";
import HeroSkillKpiUtils from "@/kpi-editor/Utils/HeroSkillKpiUtils";
import EnemyKpiUtils from "@/kpi-editor/Utils/EnemyKpiUtils";
import CharactersKpiUtils from "@/kpi-editor/Utils/CharactersKpiUtils";
import SupportSkillsConstant from "./../../../constants/SupportSkillsConstants";

export default {
    name: "QuestListView",
    components: {
        'questRepeatedView': QuestRepeatedView,
        'simulationView': QuestSimulationView
    },
    props: {
        'pQuestList': {
            type: Array,
            required: true
        },
        'pDailyQuestList': {
            type: Array,
            required: true
        },
        'shouldInit': {
            type: Boolean,
        }
    },
    data (){
        return {
            logicSimulatorService: {},
            selectedQuest: null,
            showDetailQuest: false,
            showSimulation: false,
            showDailyQuestSimulation: false,
            questList: [],
            selected: [],
            search: "",
            validatedCount: 0,
            validatePercent: 0,
            headers: [
                {text: 'Title', value: 'mainTitle',groupable: false},
                //{text: 'tuto', value: 'tutorialNum'},
                //{text: 'Main Title', value: 'questTitleCode'},
                {text: 'Objectives', value: 'questTitleCode',groupable: false},
                {text: 'Target 1', value: 'target',groupable: false},
                {text: 'KPI Boss Level', value: 'kpiBossLevel',groupable: false},
                //{text: 'KPI Boss Level Should Appears', value: 'kpiBossKilled',groupable: false},
                {text: 'KPI Boss Level Should Appears', value: 'kpiGameLevelShouldAppear',groupable: false},
                {text: 'Formula Type', value: 'formulaType', groupable: false},
                {text: 'Icon Name', value: 'iconName',groupable: false},
                {text: 'Reward ', value: 'ordered',groupable: false},
                {text: 'Skip Price', value: 'skipPrice',groupable: false},
                // {text: 'Skippable ', value: 'skippable',groupable: false},
                // {text: 'isDynamic ', value: 'isDynamic',groupable: false},
                {text: 'category ', value: 'type', groupable: false},
                {text: 'difficulty ', value: 'difficulty', groupable: false},
                {text: 'dynamicAvailable ', value: 'dynamicAvailable',groupable: false},
                {text: 'isDaily ', value: 'isDaily', groupable: false},
                {text: 'strategy ', value: 'debug.strategyClassName', groupable: false},
            ],
        }
    },
    methods: {
        loadData: function (list) {
            this.questList = [];
            if (Array.isArray(list)) {
                list.forEach(e => {
                    let clone = lodash.cloneDeep(e);
                    clone.mainTitle = this.getFullLabel(e,true);
                    this.questList.push(clone);
                });
            }
        },
        getRewardValue : function(item) {
            let questGameplay = new GameplayQuest(item,this.logicSimulatorService);
            return questGameplay.reward.ToReadableAlphabetV2();

        },
        getFullLabel: function (quest,takeFromTitle) {
            let fullLabel = "";

            ////console.log(quest);

            let gameplayQuest = new GameplayQuest(quest
                , this.logicSimulatorService
                , this.$store.state.progressTitle
                , this.$store.state.progressMainTitle
                , this.$store.state.tutorialTitle
            );

            ////console.log(gameplayQuest);
            let i = 0;
            quest.ordered.forEach(progress => {
                if (i > 0) {
                    fullLabel = fullLabel + ' and '
                }
                let progressTitle = this.$store.state.progressTitle[progress.progressTitleCode];

                if (takeFromTitle) {
                    progressTitle = this.$store.state.progressMainTitle[progress.progressTitleCode];
                }

                if (progress.eventName === 'tutorial') {
                    progressTitle = this.$store.state.tutorialTitle[progress.progressTitleCode];
                }


                //if (progressTitle) {
                //progressTitle = progressTitle.replace('[n]', progress.target);

                //gameplayQuest.displayChallenge()

                progressTitle = progressTitle.replace('[n]', progress.target?progress.target:gameplayQuest.displayChallenge());
                progressTitle = progressTitle.replace('[t]', progress.duration);
                progressTitle = progressTitle.replace('[n2]', progress.target2);
                progressTitle = progressTitle.replace('[n2]', progress.target2);

                if (progressTitle.includes('[world_name]')) {
                    let zoneLabel = QuestConstant.zoneName[progress.target.toString()];
                    progressTitle = progressTitle.replace('[world_name]', zoneLabel);
                }

                if (progress.bossType !== null || progress.bossType !== undefined) {
                    ////console.log(QuestConstant);
                    ////console.log(QuestConstant.bossType);
                    if (!progress.bossType) progress.bossType = 0;
                    let bossTypeLabel = QuestConstant.bossType[progress.bossType];
                    progressTitle = progressTitle.replace('[boss_type]',bossTypeLabel);
                }

                if (progress.eventName ==='onFruitMatch') {
                    let runeLabel = QuestConstant.runes[progress.fruitType];
                    progressTitle = progressTitle.replace('[fruit_type]',runeLabel);
                }

                if (progress.supportId != null || progress.supportId !== undefined) {
                    let supportLabel = QuestConstant.supportName[progress.supportId.toString()];
                    progressTitle = progressTitle.replace('[support_name]',supportLabel);
                }

                if (progress.heroId != null || progress.heroId !== undefined) {
                    let label = QuestConstant.heroName[progress.heroId - 1];
                    progressTitle = progressTitle.replace('[hero_name]',label);
                }

                if (progress.progressType.includes('rank')) {
                    let supportLabel = QuestConstant.rankName[progress.target];
                    progressTitle = progressTitle.replace('[rank_title]',supportLabel);
                }

                if (progress.heroId !== null || progress.heroId !== undefined) {
                    let label = QuestConstant.heroName[progress.heroId];
                    progressTitle = progressTitle.replace('[hero_name]',label);
                }

                if (progress.petId !== null || progress.petId !== undefined) {
                    let label = QuestConstant.petName[progress.petId];
                    progressTitle = progressTitle.replace('[pet_name]',label);
                }

                if (progress.powerUpType !== null || progress.powerUpType !== undefined) {
                    let label = QuestConstant.heroSkillByName[progress.powerUpType];
                    progressTitle = progressTitle.replace('[powerup_name]',label);
                }

                fullLabel = fullLabel + progressTitle;
                //}

                i++;
            })

            //let marker = gameplayQuest.stillUseDefaultStrategy === true ? "----":"****"
            return fullLabel;
        },
        getFullLabelChallenge : function(item) {
            let quest = new GameplayQuest(item,this.logicSimulatorService);
            return quest.displayChallenge();
        },

        didClickOnRow: function (index) {
            //console.log('did click on row');
            //console.log(index);
            this.selectedQuest = index;
            //this.showDetail = true;
            this.showDetailQuest = true;

        },
        didClickSimulation () {
            this.showSimulation = true;
        },
        didClickMiniQuestSimulation(){
            this.showDailyQuestSimulation = true;
        },
        close: function () {
            this.showDetail = false;
            this.showDetailQuest = false;
            this.showSimulation = false;
            this.showDailyQuestSimulation = false;
        },

        initValidatePercent(){
            let doneCount = 0;
            this.questList.forEach(q =>{
                if(q.debug && q.debug.validateStatus === "done"){
                    doneCount++;
                }
            });
            this.validatedCount = doneCount;
            this.validatePercent = Math.round((doneCount/this.questList.length)*10000)/100;
        },
        rowClass(item) {
            if (item.debug && item.debug.validateStatus === 'done'){
                return "light-green";
            }
        },
        getDailyQuestTitle(){
            return "Daily Quest Simulator ("+this.$store.state.ratios.dailyGameLevelPerDay +" lvStage for next daily quest)";
        },
        initLogicSimulator(){
            let gamePlayDataService = this.$store.state.gamePlayDataService;
            let balanceKpiUtils = new BalanceKpiUtils(gamePlayDataService);
            let supportKpiUtils = new SupportKpiUtils(this.$store.state.skillList, gamePlayDataService, SupportSkillsConstant);

            let petsData = this.$store.state.petsData;//this.$store.state.petList

            let petKpiUtils = new PetKpiUtils(petsData, gamePlayDataService);
            let heroSkillKpiUtils = new HeroSkillKpiUtils(this.$store.state.heroSkillList, gamePlayDataService);
            let balanceBuilder = this.$store.state.balanceBuilder;

            let enemysData = this.$store.state.enemysData;
            let enemysZoneData = this.$store.state.enemysZoneData;
            let enemyKpiUtils = new EnemyKpiUtils(enemysData, enemysZoneData);

            let heroesData = this.$store.state.heroesData;
            let supportersData = this.$store.state.supportersData;
            let multipleSessionKpiUtils = gamePlayDataService.multipleSessionKpiUtils;//new MultipleSessionKpiUtils(this.$store.state.multipleSessionConfig, this.$store.state.gamePlayDataService);

            let charactersKpiUtils = new CharactersKpiUtils(heroesData, supportersData, petsData, multipleSessionKpiUtils, supportKpiUtils);

            this.logicSimulatorService = new LogicSimulationService(
                balanceKpiUtils,
                gamePlayDataService,
                supportKpiUtils,
                petKpiUtils,
                heroSkillKpiUtils,
                balanceBuilder,
                enemyKpiUtils,
                charactersKpiUtils,
                multipleSessionKpiUtils
            );
        }
    },
    created() {
        this.initLogicSimulator();
        this.loadData(this.pQuestList);
        this.initValidatePercent();
    }
}
</script>

<style scoped>

</style>